<template>
    <v-card flat tile>
        <v-card-title dark class="secondary white--text text-h5">
            Documents
        </v-card-title>
        <v-card-text>
            <v-container>
                <h1>Téléchargement du Livre blanc de la DE LA DÉCLARATION NUTRITIONNELLE</h1>
                <p>Cliquez sur le bouton ci-dessous pour télécharger le fichier :</p>
                <a :href="downloadLink"
                    download="Livre blanc de la DE LA DÉCLARATION NUTRITIONNELLE.pdf">Télécharger</a>
            </v-container>
        </v-card-text>
    </v-card>
</template>

<script>
export default {
    name: 'Documents',
    data: () => ({
        downloadLink: '2024-03-23 LIVRE BLANC (MF) (Version 2 avec -fiches de Vitissia- dans corps du texte).pdf'
    }),
    mounted() {

    },
    methods: {

    },
};
</script>
