var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-card",
    { attrs: { flat: "", tile: "" } },
    [
      _c(
        "v-card-title",
        { staticClass: "secondary white--text text-h5", attrs: { dark: "" } },
        [_vm._v(" Documents ")]
      ),
      _c(
        "v-card-text",
        [
          _c("v-container", [
            _c("h1", [
              _vm._v(
                "Téléchargement du Livre blanc de la DE LA DÉCLARATION NUTRITIONNELLE"
              ),
            ]),
            _c("p", [
              _vm._v(
                "Cliquez sur le bouton ci-dessous pour télécharger le fichier :"
              ),
            ]),
            _c(
              "a",
              {
                attrs: {
                  href: _vm.downloadLink,
                  download:
                    "Livre blanc de la DE LA DÉCLARATION NUTRITIONNELLE.pdf",
                },
              },
              [_vm._v("Télécharger")]
            ),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }